<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Support ticket categories</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Support categories
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                      @click="createSupportCategory()"
                      class="btn btn-primary font-weight-bolder font-size-sm"
                      style="color: #fff;">
                    <i class="fa fa-plus"></i>
                    Add category
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">

                <v-col cols="12" sm="12" md="3">
                  <v-text-field
                      label="Category title"
                      v-model="search.info"
                      outlined
                      dense
                      v-on:keyup.enter="searchSupportCategory()"
                      @input="search.info = $event !== null ? $event : ''"
                      clearable
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                      label="Visibility"
                      v-model="visibility"
                      :items="visibilities"
                      item-text="name"
                      item-value="value"
                      outlined
                      dense
                      @change="changeVisibility"
                      v-on:keyup.enter="searchSupportCategory()"
                      @input="search.visibility = $event !== null ? $event : ''"
                      clearable
                  ></v-autocomplete>
                </v-col>


                <v-col cols="12" sm="12" md="3" v-if="currentUser.access_type != 'score'">
                  <v-autocomplete
                      label="SCORE"
                      v-model="search.score_id"
                      :items="scores"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      v-on:keyup.enter="searchSupportCategory()"
                      @input="search.score_id = $event !== null ? $event : ''"
                      clearable>
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="3" v-if="currentUser.access_type!='score'">
                  <v-autocomplete
                      label="Team assigned"
                      outlined
                      dense item-text="name"
                      item-value="value"
                      :items="sentStatus"
                      v-model="search.sent_to">
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col class="d-flex" cols="12" sm="12" md="3">
                  <v-autocomplete
                      :items="status"
                      v-model="search.is_active"
                      label="Status"
                      item-text="name"
                      item-value="value"
                      outlined
                      v-on:keyup.enter="searchSupportCategory()"
                      @input="search.is_active = $event !== null ? $event : ''"
                      dense 
                      clearable
                  >
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="12" :md="currentUser.access_type != 'score' ? 9 : 3">
                  <v-btn
                      @click="searchSupportCategory"
                      class="btn btn-primary w-35 float-right"
                      :loading="isLoading"
                      dark
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>

            </div>

            <!-- table -->
            <div class="table-responsive">

              <v-skeleton-loader
                  type="table-thead"
                  v-if="isLoading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="isLoading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>

              <table class="table" v-if="! isLoading">
                <thead>
                <tr class="px-3">
                  <th class="px-3">Category title</th>
                  <th class="px-3">Visibility</th>
                  <th class="px-3">Team assigned</th>
                  <th class="px-3">SCORE</th>
                  <th class="px-3">Status</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>

                <template>

                    <tr v-for="(item, index) in support_categories" :key="index">
                      <td  class="px-3 w-35">
                        <i class="fa fa-sort"></i> &nbsp;{{ item.name }}
                      </td>
                      <td class="w-25">
                          <div v-if="item.is_visible_on_support_tickets">
                            <span class="mx-3 badge text-lg badge-success" v-if="item.is_visible_on_support_tickets">Enroller</span>
                          </div>
                          <br>
                          <div v-if="item.is_visible_on_online_exam_support">
                            <span class="badge text-lg badge-success">Online candidate</span>
                          </div>
                          <br>
                          <div v-if="item.is_refund_category">
                            <span class="badge text-lg">Refund request</span>
                          </div>
                      </td>
                      <td class="px-3">
                        <span>{{ item.sent_to_text ? item.sent_to_text : 'NA' }}</span>
                      </td>
                      <td class="px-3">
                        <ul v-if="item.scores.length > 0">
                          <li v-for="(score, index) in item.scores" :key="index"> {{ score }}</li>
                        </ul>
                        <span v-else>NA</span>
                      </td>
                      <td class="px-3">
                           <span class="badge text-lg"
                                 v-bind:class="{ 'badge-success': item.is_active, 'badge-danger': !item.is_active }"
                           >{{ item.status_text }}</span>
                      </td>

                      <td class="pr-0 text-center">
                        <template>
                          <b-dropdown
                              size="sm"
                              variant="link"
                              toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                              no-caret
                              right
                              no-flip
                          >
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <div class="navi navi-hover min-w-md-250px">
                              <b-dropdown-text tag="div" class="navi-item">
                                <a @click="editSupportCategory(item)" class="navi-link">
                                  <span class="navi-icon">
                                      <i class="fas fa-edit"></i>
                                  </span>
                                  <span class="navi-text">Edit</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item">
                                <a class="navi-link" @click.prevent="deleteSupportCategory(item.id)">
                                  <span class="navi-icon">
                                   <i class="fas fa-trash"></i>
                                </span>
                                  <span class="navi-text">Delete</span>
                                </a>
                              </b-dropdown-text>
                            </div>
                          </b-dropdown>
                        </template>
                      </td>
                    </tr>
                    <tr v-if="support_categories.length == 0">
                      <td class="text-center px-3" colspan="6">No items found.</td>
                    </tr>

                </template>

              </table>

              <b-pagination
                  v-if="support_categories.length > 0"
                  class="pull-right mt-7"
                  @input="getAllSupportCategory"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  :disabled="isLoading"
                  last-number
              ></b-pagination>
            </div>
            <!-- table ends -->
            <v-overlay :value="isSorting">
              <v-progress-circular
                  indeterminate
                  color="dark"
              ></v-progress-circular>
            </v-overlay>
          </div>
        </div>
      </div>

      <create-or-update ref="create-or-update" @refresh="getAllSupportCategory"></create-or-update>

    </div>
  </v-app>
</template>

<script>
import SupportCategoryService from "@/services/support/support-category/SupportCategoryService";
import CreateOrUpdate from "./CreateOrUpdate";
import draggable from 'vuedraggable'
import ScoreService from "@/services/score/score/ScoreService";

const supportCategory = new SupportCategoryService();
const score = new ScoreService();

export default {
  components:{
    CreateOrUpdate,
   draggable
  },
  data(){
    return{
      visibility: '',
      search:{
        info:'',
        is_visible_on_support_tickets: '',
        is_visible_on_online_exam_support: '',
        is_refund_category: '',
        score_id:'',
        is_active: 1,
        sent_to:'',
      },
      status: [
        {name: 'Active', value: 1},
        {name: 'Inactive', value: 0},
      ],
      support_categories:[],
      scores:[],
      total: null,
      perPage: null,
      page: null,
      isSorting: false,
      isLoading: false,
      drag: false,
      currentUser:{},
      sentStatus: [
        { name: "Federal", value: "federal" },
        { name: "Score", value: "score" }
      ],
      visibilities: [
        { name: "Enroller", value: "enroller" },
        { name: "Candidate", value: "candidate" },
        { name: "Refund request", value: "refund" },
      ],
    }
  },
  methods:{
    changeVisibility() {
      if(this.visibility == '' || this.visibility == null) {
        this.search.is_visible_on_support_tickets = '';
        this.search.is_visible_on_online_exam_support = '';
        this.search.is_refund_category = '';
      }
      else if(this.visibility == 'enroller') {
        this.search.is_visible_on_support_tickets = 1;
      }
      else if(this.visibility == 'refund') {
        this.search.is_refund_category = 1;
      } else {
        this.search.is_visible_on_online_exam_support = 1;
      }
    },
    getAllScore(){
      score
          .all()
          .then(response => {
            this.scores=response.data.scores;
          })
          .catch((err) => {});
    },
    getAllSupportCategory(){
      this.isLoading = true;
      supportCategory
          .paginate(this.search, this.page)
          .then(response => {
            this.support_categories=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.isLoading = false;
          })
          .catch((err) => {

          });
    },
    searchSupportCategory(){
      this.getAllSupportCategory();
    },
    createSupportCategory(){
      this.$refs['create-or-update'].createSupportCategory();
    },
    editSupportCategory(item){
      this.$refs['create-or-update'].editSupportCategory(item);
    },
    deleteSupportCategory(item){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            supportCategory
                .delete(item)
                .then((response) => {
                  this.getAllSupportCategory();
                  this.$snotify.success("Support Category Successfully Deleted");
                })
                .catch((err) => {
                  this.isLoading = false;
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
    sort(){
      this.isSorting = true;
      supportCategory
          .sort(this.support_categories)
          .then(response => {
            this.getAllSupportCategory();
            this.isSorting = false;
            this.$snotify.success('Support Category!!');
          })
          .catch(err => {

          });
    },
    getCurrentUser() {
      this.currentUser =  this.$store.getters.currentUser;
    }
  },
  mounted() {
    this.getCurrentUser();
    this.getAllSupportCategory();
    this.getAllScore();
  }
}
</script>