<template>
  <v-dialog v-model="dialog" max-width="800">
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>{{ title }} Support Category</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                  :error="$v.support_category.name.$error"
                  outlined
                  dense
                  v-model="support_category.name">
                <template v-slot:label>
                  Support title <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.support_category.name.$error">This information is required</span>
              <span class="text-danger" v-if="errors.name" >**{{errors.name[0]}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="12" v-if="currentUser.access_type!='score'">
              <v-autocomplete
                  label="SCORE"
                  item-text="name"
                  item-value="id"
                  v-model="support_category.score_ids"
                  :items="scores"
                  multiple
                  small-chips
                  deletable-chips
                  outlined
                  dense
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="allSelectScores">
                    <v-list-item-action>
                      <v-icon :color="support_category.score_ids > 0 ? 'indigo darken-4' : ''">
                        {{ iconScore }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Select all
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-1"></v-divider>
                </template>
                <template v-slot:no-data>
                  <div class="no-data-auto-complete text-center">No data available </div>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="12" :md="currentUser.access_type!='score'? 6 : ''" v-if="currentUser.access_type!='score'">
              <v-autocomplete
                  label="Team to assign"
                  outlined
                  dense item-text="name"
                  item-value="value"
                  :items="sentStatus"
                  v-model="support_category.sent_to">
                <template v-slot:no-data>
                  <div class="no-data-auto-complete text-center">No data available </div>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="12" :md="currentUser.access_type!='score'? 6 : 12">
              <v-select
                  v-model="support_category.type"
                  :items="types"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  :error="$v.support_category.type.$error"
                  menu-props="closeOnContentClick"
              >
                <template v-slot:label>
                  Support type <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.support_category.type.$error">This information is required</span>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <label class="text-body">
                Description
              </label>
              <ckeditor
                  :config="editorConfig"
                  v-model="support_category.description" >
              </ckeditor>
            </v-col>

            <v-col cols="12" sm="12" md="3">
              <span class="font-weight-medium">Visible to online candidates</span>
              <v-switch
                  v-model="support_category.is_visible_on_online_exam_support"
                  :label="support_category.is_visible_on_online_exam_support ?'Active':'Inactive'"
                  hide-details
              ></v-switch>
            </v-col>

            <v-col cols="12" sm="12" md="3">
              <span class="font-weight-medium">Visible to enrollers</span>
              <v-switch
                  v-model="support_category.is_visible_on_support_tickets"
                  :label="support_category.is_visible_on_support_tickets ?'Active':'Inactive'"
                  hide-details
              ></v-switch>
            </v-col>


            <v-col cols="12" sm="12" md="3">
              <span class="font-weight-medium">Refund category</span>
              <v-switch
                  v-model="support_category.is_refund_category"
                  :label="support_category.is_refund_category ?'Active':'Inactive'"
                  hide-details
              ></v-switch>
            </v-col>
<!--            <v-col cols="12" sm="12" md="4">-->
<!--              <span class="font-weight-medium">Is This System Category ?</span>-->
<!--              <v-switch-->
<!--                  v-model="support_category.is_system_category"-->
<!--                  :label="support_category.is_system_category ?'Yes':'No'"-->
<!--                  hide-details-->
<!--              ></v-switch>-->
<!--            </v-col>-->
            <v-col cols="6" md="3">
              <span class="font-weight-medium">Status</span>
              <v-switch
                  v-model="support_category.is_active"
                  :label="support_category.is_active ?'Active':'Inactive'"

                  hide-details></v-switch>
              <span class="text-danger" v-if="errors.is_active" >**{{errors.is_active[0]}}</span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            class="cancel-btn"
            text
            x-large
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
            color="btn btn-primary"
            dark
            x-large
            @click="createOrUpdate()"
            :loading="loading"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import SupportCategoryService from "@/services/support/support-category/SupportCategoryService";
import {required, numeric, requiredIf} from "vuelidate/lib/validators";
import ScoreService from "@/services/score/score/ScoreService";
const score = new ScoreService();
const supportCategory=new SupportCategoryService();
export default {
  validations:{
    support_category:{
      name:{required},
      type:{required},
    },
  },
  data(){
    return{
      title:'',
      dialog: false,
      edit: false,
      loading: false,
      errors:[],
      scores:[],
      currentUser:{},
      support_category:{
        name:'',
        description:'',
        type:'',
        position:'',
        score_ids:'',
        sent_to: '',
        is_refund_category:false,
        is_system_category:false,
        is_visible_on_online_exam_support:false,
        is_visible_on_support_tickets:false,
        is_active:true,
      },
      sentStatus: [
        { name: "Federal", value: "federal" },
        { name: "Score", value: "score" }
      ],
      types: [
        {name: 'General', id: 'general'},
        {name: 'Extension', id: 'extension'},
        {name: 'Refund', id: 'refund'},
        {name: 'Payment', id: 'payment'},
        {name: 'Appeal', id: 'appeal'},
        {name: 'Feedback', id: 'feedback'},
        {name: 'Shipment', id: 'shipment'},
        {name: 'Other', id: 'other'},
      ],
      editorConfig: {
        toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'NumberedList', 'BulletedList' ] ],
        versionCheck: false
      },
    }
  },
  methods:{
    back() {
      this.$router.push({
        name: "support-category",
      });
    },
    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
      this.$v.$reset();
    },
    resetForm(){
      this.support_category={
        name:'',
        description:'',
        position:'',
        score_ids:'',
        sent_to: '',
        is_visible_on_online_exam_support:false,
        is_visible_on_support_tickets:false,
        is_active:true,
      };
    },
    openDialog() {
      this.dialog = true;
    },
    allSelectScores() {
      this.$nextTick(() => {
        if (this.allSelectScore) {
          this.support_category.score_ids = []
        } else {
          this.support_category.score_ids = [];
          this.scores.forEach(score => {
            this.support_category.score_ids.push(score.id);
          });
        }
      })
    },
    createSupportCategory() {

      this.resetForm();
      this.openDialog();
      this.edit = false;
      this.title = "Create";
    },
    editSupportCategory(item) {
      this.openDialog();
      this.edit = true;
      this.support_category = item;
      this.title = "Edit";
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function () {
      this.loading = true;
      supportCategory
          .create(this.support_category)
          .then(response => {
            this.$snotify.success("Support category added");
            this.closeDialog();
            this.resetForm();
            this.errors=[];
            this.loading = false;
            this.$emit('refresh');
          })
          .catch(error => {
            this.errors = error.errors;
          })
    },
    update: function () {
      this.loading = true;
      supportCategory
          .update(this.support_category.id, this.support_category)
          .then(response => {
            this.$snotify.success("Support category updated");
            this.closeDialog();
            this.resetForm();
            this.errors=[];
            this.loading = false;
            this.$emit('refresh');
          })
          .catch(error => {
            this.errors = error.errors;
          })

    },
    getAllScore(){
      score
          .all()
          .then(response => {
            this.scores = response.data.scores;
          })
          .catch((err) => {

          });
    },
    getCurrentUser() {
      this.currentUser =  this.$store.getters.currentUser;
    }
  },
  mounted() {
    this.getCurrentUser();
    if(this.currentUser.access_type == 'score') {
      this.support_category.sent_to = 'score';
      this.support_category.score_ids = this.currentUser.score_id;
    }
    this.getAllScore();
  },
  computed: {
    allSelectScore () {
      return this.support_category.score_ids.length === this.scores.length
    },
    selectScore () {
      return this.support_category.score_ids.length > 0 && !this.allSelectScore
    },
    iconScore () {
      if (this.allSelectScore) return 'mdi-close-box'
      if (this.selectScore) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }
  }
}
</script>